/* analytics */
import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel'

import mediumZoom from 'medium-zoom'
global.mediumZoom = mediumZoom


global.mediumZoomOptions = {
    background: '#2e2e2e',
    scrollOffset: 0,
}


import iziToast from 'izitoast'
global.iziToast = iziToast

iziToast.settings({position: 'topRight'})

import axios from 'axios'
global.axios = axios

global.Vue = require('vue')
Vue.config.productionTip = false

import newsletterForm from './newsletterForm.js'
import navComponent from './navigation.js'

window.addEventListener('load', function(){
    window.cookieconsent.initialise({
      "palette": {
        "popup": {
          "background": "#edeff5",
          "text": "#f6aba7"
        },
        "button": {
          "background": "#f8c1be",
          "color" : "#fff"
        }
      },
      "theme": "edgeless",
      "content": {
        "message": "En poursuivant votre navigation sur ce site, vous acceptez l'installation et l'utilisation de cookies sur votre navigateur.",
        "dismiss": "Continuer",
        "link": "En savoir plus",
        "href" : "https://www.gouvernement.fr/donnees-personnelles-et-cookies"
      }
    });
    document.querySelector('.front-newsletter') !== null ? newsletterForm.init() : null
  
    mediumZoom('.post img')

    document.querySelector('#navPrimary') !== null ? navComponent.init() : null

})

