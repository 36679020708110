export default{
    el: false,
    elYPos : false,
    menu: false,
    sticked: false,
    open: false,
    oldHeight: false,

    /**
     * [Init | Stock quelques variables et attach les events listener]
     */
    init: function(){
        this.el = $('#navPrimary'); //- Header with .header-1 and .header-navigation
        this.elYPos = $(this.el).height(); //- Y bottom position of Header / this.el
        this.menu = $('#menu'); //- Menu
        this.menuYPost = $(this.el).offset().top;
        this.navicon = $('#navicon'); //- Hamburger button

        this.attachEvent(); //- Attach events to DOM Elements

        $(window).scrollTop() >= this.menuYPost && !this.sticked ? this.setSticky() : null;
    },

    attachEvent: function(){

        if (this.isMobile()) {
            $(this.menu).find('ul>li>a').on('click', function(e){
                if ($(e.target).parent().find('.sub-menu').length === 0) {return true}
                e.preventDefault();
                this.toggleUnderNav($(e.target).parent());
                $(e.target).parent().toggleClass('is-open')
                // $(e.target).parent().hasClass('is-open') ? this.hideUnderNav($(e.target).parent(), $(e.target).parent().find('> .sub-menu')) : this.showUnderNav($(e.target).parent(), $(e.target).parent().find('> .sub-menu'))
            }.bind(this))
        }

        $(this.navicon).on('click', function(e){
            !this.open && this.isMobile() && !$(this.navicon).hasClass('is-open') ? this.showNav() : null;
            this.open && this.isMobile() && $(this.navicon).hasClass('is-open') ? this.hideNav() : null;
            this.open = !this.open;
        }.bind(this));


        $(window).on('scroll', function(){
            $(window).scrollTop() >= this.menuYPost && !this.sticked ? this.setSticky() : null;
            $(window).scrollTop() <= this.menuYPost && this.sticked ? this.setUnSticky() : null;
        }.bind(this));

        // $(this.menu).on('click', 'a.nav-item', function(e){
        //     e.preventDefault();
        //     this.scrollToSection($(e.target).attr('href'), $(e.target));
        // }.bind(this));

        $(window).on('resize', function(){
            if (!this.isMobile() && $(this.menu).hasClass('is-closed')) {
                // $(this.navicon).addClass('is-open').attr('aria-expanded','true');
                // $(this.menu).removeClass('is-closed').fadeIn(250).addClass('is-open');
            }
            console.log($(this.menu).height())
            if (!this.isMobile()) {
                $(this.menu).css('height', 'auto');
            }
        }.bind(this));

    },

    setSticky : function(){
        if (!this.sticked) {
            // console.log('sticky');
            $(this.el).addClass('sticky');
            $('body').addClass('nav-sticked')
            this.sticked = true;
        }
    },

    setUnSticky: function(){
        if (this.sticked) {
            // console.log('unsticky');
            $(this.el).removeClass('sticky');
            $('body').removeClass('nav-sticked')
            this.sticked = false;
        }
    },


    /** [show | Only Mobile | Show navigation with animation] */
    showNav: function(){
        $(this.navicon).addClass('is-open').attr('aria-expanded','true');
        // $(this.menu).removeClass('is-closed').addClass('is-open').fadeIn(250);
        $(this.menu).removeClass('is-closed').addClass('is-open');
        $(this.menu).css('height', 0);

        var height = $(this.menu).css('height', 'auto').height() + 50;

        $(this.menu).height(0).stop().animate({
            height: height
        }, 400, function() {

        })

        $(this.menu).find('li').each(function(i){
            $(this).css('opacity', 0);
            $(this).delay(i * 10).animate({
                opacity: 1
            })
        });
    },

    /** [hide | Only Mobile | Hide navigation with animation] */
    hideNav : function(){
        $(this.navicon).removeClass('is-open').attr('aria-expanded','false');
        $(this.menu).addClass('is-closed').removeClass('is-open');
        $('body').removeClass('.lock-position ')

        var menu = $(this.menu);
        menu.animate({
            height: 0
        });
    },

    toggleUnderNav: function(parent){
        if ($(parent).hasClass('is-open')) {
            $('body').removeClass('.lock-position ')
            $(this.menu).animate({
                height: this.oldHeight
            }, 400)
        }else{
            this.oldHeight = $(this.menu).height()
            $('body').addClass('.lock-position ')
            $(this.menu).animate({
                height: $(window).height()
            }, 400)
            
        }

    },

    // showUnderNav: function(parent, element){
    //     $(parent).addClass('is-open')
    //     console.log($(parent))

    //     var windowTop = $(window).scrollTop()
    //     var windowBottom = windowTop + $(window).height()

    //     var elTop = $(element).offset().top
    //     var elBottom = elTop + $(element).height()

    //     var visibleTop = elTop < windowTop ? windowTop : elTop
    //     var visibleBottom = elBottom > windowBottom ? windowBottom : elBottom;

    //     var height = visibleBottom - visibleTop

    //     console.log(height)
    //     $(element).height(0).stop().animate({
    //         height: height
    //     }, 400, function(){
    //         // $(element).css()
    //     })
    // },

    // hideUnderNav: function(parent, element){
    //     $(parent).removeClass('is-open')
    //     console.log('hideUnderNav', $(parent))
    //     $(element).animate({
    //         height: 0
    //     })
    // },

    /** [isMobile | Check if user are on mobile] */
    isMobile: function(){
        return $(window).width() < 1060 ? true : false;
    },

    /** [isOpen | Only mobile | Check if navigation is open ] */
    isOpen: function(){
        return $(this.menu).hasClass('closed');
    },

    /** [isHomePage | @return if user is on homepage] */
    isHomePage: function(){
        return $('.page').hasClass('homepage');
    },

    /**
     * [scrollToSection | Scroll to target section if exist | /!\ Only on Homepage]
     * @param  {[string]} href [link href, `#section`]
     * @param  {[DOM Element[jQuery obj]]} link [nav item clicked]
     */
    scrollToSection: function(href, link){
        var target = $(href);

        //- If target section exists on homepage, else do nothing
        if (target.length) {
            //- If we are on mobile, close nav, else continue
            this.isMobile() ? function(){ this.hideNav(); this.open = false; }.bind(this)() : null;

            //- If is mobile -> nav height is lower than full header height
            var headerHeight = this.isMobile() ? 50 : $(this.menu).height();
            $('html, body').animate({
                scrollTop: (target.offset().top - headerHeight)
            }, 500);

            //- Switch active class to good nav link
            $(this.menu).find('a').removeClass('active');
            link.addClass('active');

        }
    }
}