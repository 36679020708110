export default{
	$el: false,
	$input : false,
	$button: false,
	loading: false,

	init(){
		console.log('ici')
		this.$el = document.querySelector('.front-newsletter');
		this.$input = this.$el.querySelector('input');
		this.$button = this.$el.querySelector('button');

		console.log(this)
		this.$button.addEventListener('click', this.submitForm.bind(this))
	},

	submitForm(e){
		if (this.loading) {
			return false
		}

		this.loading = true
		console.log('e', e)

		if (this.$input.value.length > 5) {
			console.log(this.$input.value)
			iziToast.success({title:'Succès', message: 'Merci de votre inscription !', timeout: false})
			this.$input.value = ''
			this.loading = false
		}else{
			iziToast.error({title:'Erreur', message: 'Champ adresse e-mail incorrect !', timeout: 2000})
			this.loading = false
		}
	}

}